<template>

    <div   class="login uk-cover-container uk-flex uk-flex-center uk-margin-large-top uk-height-viewport uk-overflow-hidden" >

            <div class="uk-width-medium uk-padding-small uk-position-z-index" uk-scrollspy="cls: uk-animation-fade">
                
                <div class="uk-text-center uk-margin">
                    <!-- <img src="img/login-logo.svg" alt="Logo"> -->
            <h3> <h2><i>Admin Emprendedores</i></h2> Inicia Sesión</h3>
                </div>

                <!-- login -->
                <form class="toggle-class" id="loginForm">
                    <fieldset class="uk-fieldset">
                        <div class="uk-margin-small">
                            <div class="uk-inline uk-width-1-1">
                                <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: user"></span>
                                <input class="uk-input uk-border-pill" v-model="mail" required placeholder="Correo registrado" type="text">
                            </div>
                        </div>
                        <div class="uk-margin-small">
                            <div class="uk-inline uk-width-1-1">
                                <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: lock"></span>
                                <input class="uk-input uk-border-pill" v-model="pass" required placeholder="Contraseña" type="password">
                            </div>
                        </div>
                        <div class="uk-margin-bottom">
                            <button type="submit" class="uk-button uk-button-primary uk-border-pill uk-width-1-1">  Iniciar Sesión</button>
                        </div>
                    </fieldset>
                </form>
                <!-- /login -->

                <!-- recover password -->
                <form class="toggle-class" action="login-dark.html" hidden>
            <p class="uk-text-left"> Se enviará un link para cambiar la contraseña.</p>
                    <div class="uk-margin-small">
                        <div class="uk-inline uk-width-1-1">
                            <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: mail"></span>
                            <input class="uk-input uk-border-pill" placeholder="Correo registrado" required type="text">
                        </div>
                    </div>
                    <div class="uk-margin-bottom">
                        <button type="button" @click="reset()" class="uk-button uk-button-primary uk-border-pill uk-width-1-1">ENVIAR LINK</button>
                    </div>
                </form>
                <!-- /recover password -->
                
                <!-- action buttons -->
                <div>
                    <div class="uk-text-center">
                        <a class="uk-link-reset uk-text-small toggle-class" data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade">¿Olvidaste tu contraseña?</a>
                        <a class="uk-link-reset uk-text-small toggle-class" data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade" hidden><span data-uk-icon="arrow-left"></span> Regresar a Login</a>
                    </div>
                </div>
                <!-- action buttons -->
            </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { sendPasswordResetEmail, getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useRouter } from 'vue-router'
export default {
    setup(){
        const mail = ref('')
        const pass = ref('')
        const reset = function () {
              if(mail.value){
                  sendPasswordResetEmail(getAuth(), mail.value).then(function(){
                    UIkit.notification('Se ha enviado un correo con con las instrucciones')
                  }).catch(function(error){
                    if(error.code){
                        UIkit.notification(error.code + ' : ' + error.message,'danger')
                    }
                  })
              } else {
                  UIkit.notification('Ingresa tu correo registrado')
                  return
              }
        }
  
        
        const router = useRouter()
        if(getAuth().currentUser ){
            if(!sessionStorage.getItem('uid')){
                sessionStorage.setItem('uid',getAuth().currentUser.uid)
            }
            // router.push('/admin')
        }
  
        onMounted(()=>{
          document.getElementById('loginForm').addEventListener('submit',(e)=>{
              e.preventDefault()
              signInWithEmailAndPassword(getAuth(),mail.value, pass.value)
                  .then((userCredential) => {
                      // Signed in
                      var user = userCredential.user;
                      if(!user){
                          return UIkit.notification('Ocurrió un error, intenta de nuevo', 'danger')
                      }

                      let uid = userCredential.user.uid
                      sessionStorage.setItem('uid',uid)
                      router.push('/admin')
                      // ...
                  })
                  .catch((error) => {
                      var errorCode = error.code;
                      var errorMessage = error.message;
                      let message = String(error.code).split('-').join(' ').split('/')
                      message.shift()
                      UIkit.notification(message.join(' ').toLocaleUpperCase(), 'danger')
                  });
          })
        })
  
        return{
          mail,
          pass,
          reset
        }
  
    }
  
  }
</script>

<style>

</style>